var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot_main"},[_c('div',{staticClass:"footer_top"}),_c('div',{staticClass:"footer_box"},[_c('div',{staticClass:"footer_cont w_1200 m_auto"},[_vm._m(0),_c('div',{staticClass:"ul_cont"},[_vm._m(1),_c('ul',[_c('p',[_vm._v("关于中工")]),_vm._m(2),_c('li',{on:{"click":function($event){return _vm.$router.push('/action')}}},[_c('a',{attrs:{"href":"javascript:;","rel":"noopener noreferrer"}},[_vm._v("新闻中心")])]),_c('li',{on:{"click":function($event){return _vm.$router.push('/aboutUs')}}},[_c('a',{attrs:{"href":"javascript:;","rel":"noopener noreferrer"}},[_vm._v("人才招聘")])]),_vm._m(3),_vm._m(4)]),_c('ul',[_c('p',[_vm._v("服务支持")]),_vm._m(5),_vm._m(6),_c('li',{on:{"click":function($event){return _vm.$router.push('/aboutUs')}}},[_c('a',{attrs:{"href":"javascript:;","rel":"noopener noreferrer"}},[_vm._v("联系我们")])])])])]),_c('div',{staticClass:"deep_cont w_1200 m_auto flex"},[_c('div',{staticClass:"copy_cont"},[_c('div',{staticClass:"text_info"},[_c('p',[_vm._v("Copyright © 2015-"+_vm._s(new Date().getFullYear())+" kjcxchina.com All Rights Reserved")]),_vm._m(7)])])])]),(_vm.maskState)?_c('div',{staticClass:"mask_box"},[_c('div',{staticClass:"aboutUs_box"},[_c('p',{staticClass:"close cursor",on:{"click":function($event){_vm.maskState=false}}},[_vm._v("×")]),_vm._m(8),_c('p',{staticClass:"text"},[_vm._v("扫码添加联系我们")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_about_us"},[_c('p',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/pc/footer/logo.png"),"alt":""}})]),_c('div',{staticClass:"phone_cont"},[_c('p',{staticClass:"tips"},[_vm._v("联系我们")]),_c('div',{staticClass:"phone_num flex"},[_c('img',{attrs:{"src":require("@/assets/pc/footer/phone.png"),"alt":""}}),_c('span',[_vm._v("400-0123-823")])]),_c('p',{staticClass:"adr_text"},[_vm._v(" 北京市东城区首东国际大厦B座4号楼17层 ")]),_c('div',{staticClass:"code_cont"},[_c('p',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/pc/footer/code_img.png"),"alt":""}})]),_c('p',{staticClass:"text"},[_vm._v("官方公众号")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('p',[_vm._v("推荐产品")]),_c('li',[_c('a',{attrs:{"href":"http://open.kskstudy.com/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("中工KSK开放平台")])]),_c('li',[_c('a',{attrs:{"href":"https://npx.kskstudy.com/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("中工云课堂")])]),_c('li',[_c('a',{attrs:{"href":"https://zgyxk.kskstudy.com/index","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("中工云选课")])]),_c('li',[_c('a',{attrs:{"href":"https://npx.kskstudy.com/teacherList","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("大师来了")])]),_c('li',[_c('a',{attrs:{"href":"https://zgyxk.kskstudy.com/researchList","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("企业研学")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.kjcxchina.com/aboutUs","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("公司简介")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":" http://open.kskstudy.com/History","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("发展历程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;","rel":"noopener noreferrer"}},[_vm._v("社会责任")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;","rel":"noopener noreferrer"}},[_vm._v("政企服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;","rel":"noopener noreferrer"}},[_vm._v("合作伙伴")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("版权所有：中工科创 京ICP备"),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" 16012224号-1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/code_img.png"),"alt":""}})])
}]

export { render, staticRenderFns }